.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: lightgray;
  /* background-image: url("https://i.imgur.com/3tgcIND.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
 }

.box {
  width: 100%;
  height: 100%;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 3.5rem;
  margin-bottom: 2.5rem;
}

.example{
  width: 45rem;
  text-align: center;
  margin: 5px;
}

.example p {
  font-size: 1.05rem;
}

.example h3 {
  font-size: 1.5rem;
}

.example img{
  max-width: 550px;
  border: 1px inset;
  border-radius: 5px;
  box-shadow: 1px 1px 1px white;
}

.example img:hover{
  box-shadow: 1px 1px 1px gray;
  border-color: lightgray;
}


@media only screen and (max-width: 1500px) {
  .content{
    width: 100%;
    height: 100%;
    min-height: 920px;
    background-color: lightgrey;    
  }
  .box{
    margin-top: 5rem;
    width: 75%;
  }
}

@media only screen and (max-width: 600px) {
  .example img{
    max-width: 350px;
    max-height: 175px;
  }
}