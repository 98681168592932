body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  background-image: url("https://images.unsplash.com/photo-1490131784822-b4626a8ec96a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80");
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  background-color: darkgrey;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .App {
    min-height: 850px;
    background-image: url("https://i.imgur.com/kFIu5Zh.jpg")
  }
}

@media only screen and (min-height: 1000px) {
  .App{
    min-height: 1500px;
  }
}

.Nav {
  width: 100%;
  height: 3rem;
  display: grid;
  grid-template-areas: "name nav";
  border-bottom: 1px solid darkgrey;
  box-shadow: 0 2px 3px darkgray;
  background-color: darkslategray;
  position: fixed;
}

.name {
  grid-area: "name";
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: white;
}

.title {
  padding: 0rem 1rem;
}

a{
  color: white;
}

.icons{
  font-size: 1.5rem;
  padding: 0rem 1rem;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
  color: white;
}

.icons:hover {
  color: black;
  cursor: pointer;
}

.mobile{
  display: none;
}

.links {
  grid-area: "nav";
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}

.links span{
  padding: 0 1.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: white;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.links span:hover{
  background-color: black;
  cursor: pointer;
}

.navLink {
  text-decoration: none;
}

@media only screen and (max-width: 1500px) {
  .Nav {
    height: 5rem;
  }
  .icons{
    font-size: 2rem;
  }
  .links{
    font-size: 1.25rem;
  }
  .title{
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    display: none;
  }
}
.main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.container {
  width: 35rem;
  height: 22rem;
  margin: 12rem;
  text-align: center;
  color: black;
}

.container h1 {
  font-size: 4rem;
  font-family: 'Nunito', sans-serif;
  font-weight: lighter;
  letter-spacing: .5rem;
  border-bottom: 2px dotted darkslategray;
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.container h2 {
  font-size: 2rem;
  padding: 1rem 0;
  margin-top: 1rem;
  font-family: 'Oswald', sans-serif;
}

button {
  background-color: none;
  padding: .5rem;
  margin: 0 4px;
  width: 4.75rem;
  border: none;
  border-radius: .5rem;
  box-shadow: 0 2px 3px darkgray;
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  font-weight: bold;
  color: darkslategray;
  word-spacing: .25rem;
}

button:hover {
  box-shadow: 1px 3px 4px darkgray;
  background-color: lightgrey;
  cursor: pointer;
}

.mainLink {
  text-decoration: none;
  color: darkslategray;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 80%;
  }

  .container h1 {
    font-size: 2.25rem;
  }
  .container h2 {
    font-size: 1.5rem;
  }
}
.content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: lightgray;
  /* background-image: url("https://i.imgur.com/3tgcIND.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
 }

.box {
  width: 100%;
  height: 100%;
  color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 3.5rem;
  margin-bottom: 2.5rem;
}

.example{
  width: 45rem;
  text-align: center;
  margin: 5px;
}

.example p {
  font-size: 1.05rem;
}

.example h3 {
  font-size: 1.5rem;
}

.example img{
  max-width: 550px;
  border: 1px inset;
  border-radius: 5px;
  box-shadow: 1px 1px 1px white;
}

.example img:hover{
  box-shadow: 1px 1px 1px gray;
  border-color: lightgray;
}


@media only screen and (max-width: 1500px) {
  .content{
    width: 100%;
    height: 100%;
    min-height: 920px;
    background-color: lightgrey;    
  }
  .box{
    margin-top: 5rem;
    width: 75%;
  }
}

@media only screen and (max-width: 600px) {
  .example img{
    max-width: 350px;
    max-height: 175px;
  }
}
