.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 35rem;
  height: 22rem;
  margin: 12rem;
  text-align: center;
  color: black;
}

.container h1 {
  font-size: 4rem;
  font-family: 'Nunito', sans-serif;
  font-weight: lighter;
  letter-spacing: .5rem;
  border-bottom: 2px dotted darkslategray;
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.container h2 {
  font-size: 2rem;
  padding: 1rem 0;
  margin-top: 1rem;
  font-family: 'Oswald', sans-serif;
}

button {
  background-color: none;
  padding: .5rem;
  margin: 0 4px;
  width: 4.75rem;
  border: none;
  border-radius: .5rem;
  box-shadow: 0 2px 3px darkgray;
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  font-weight: bold;
  color: darkslategray;
  word-spacing: .25rem;
}

button:hover {
  box-shadow: 1px 3px 4px darkgray;
  background-color: lightgrey;
  cursor: pointer;
}

.mainLink {
  text-decoration: none;
  color: darkslategray;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 80%;
  }

  .container h1 {
    font-size: 2.25rem;
  }
  .container h2 {
    font-size: 1.5rem;
  }
}