.Nav {
  width: 100%;
  height: 3rem;
  display: grid;
  grid-template-areas: "name nav";
  border-bottom: 1px solid darkgrey;
  box-shadow: 0 2px 3px darkgray;
  background-color: darkslategray;
  position: fixed;
}

.name {
  grid-area: "name";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
}

.title {
  padding: 0rem 1rem;
}

a{
  color: white;
}

.icons{
  font-size: 1.5rem;
  padding: 0rem 1rem;
  transition: color 0.2s linear;
  color: white;
}

.icons:hover {
  color: black;
  cursor: pointer;
}

.mobile{
  display: none;
}

.links {
  grid-area: "nav";
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.links span{
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  color: white;
  transition: background-color 0.2s linear;
}

.links span:hover{
  background-color: black;
  cursor: pointer;
}

.navLink {
  text-decoration: none;
}

@media only screen and (max-width: 1500px) {
  .Nav {
    height: 5rem;
  }
  .icons{
    font-size: 2rem;
  }
  .links{
    font-size: 1.25rem;
  }
  .title{
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    display: none;
  }
}