.App {
  background-image: url("https://images.unsplash.com/photo-1490131784822-b4626a8ec96a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80");
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  background-color: darkgrey;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .App {
    min-height: 850px;
    background-image: url("https://i.imgur.com/kFIu5Zh.jpg")
  }
}

@media only screen and (min-height: 1000px) {
  .App{
    min-height: 1500px;
  }
}
